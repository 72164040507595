.MoneyOrder .InstrumentLogPopup {
    width: 90%;
    z-index:5;
}

.Mainview .MoneyOrder .InstrumentLogPopup h4 {
    margin: 0px;
}

.MoneyOrder .InstrumentLog .FormSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.MoneyOrder .InstrumentLog .FormSection input {
    width: 410px
}


.Mainview .MoneyOrder .InstrumentLog fieldset {
    display:flex;
    flex-direction: column;
}

.Mainview .MoneyOrder .InstrumentLog fieldset input, .Mainview .MoneyOrder .InstrumentLog fieldset select {
    margin: 5px;
    background-color: white;
}

.Mainview .MoneyOrder .InstrumentLog .Birthdate {
    display:flex;
    justify-content: flex-end;
    align-items: center;
}

.Mainview .MoneyOrder .InstrumentLog input.Month, .Mainview .MoneyOrder .InstrumentLog input.Day {
    width: 80px;
}

.Mainview .MoneyOrder .InstrumentLog input.Year {
    width: 110px;
}

.MoneyOrder .InstrumentLog .Continue {
    width: 100%;
}

.Mainview .MoneyOrder .InstrumentLog .State, .Mainview .MoneyOrder .InstrumentLog .Zip, .Mainview .MoneyOrder .InstrumentLog .IdIssuer, .Mainview .MoneyOrder .InstrumentLog .IdType {
    display:inline-block;
}

.Mainview .MoneyOrder .InstrumentLog .State select,.Mainview .MoneyOrder .InstrumentLog .Zip input, .Mainview .MoneyOrder .InstrumentLog .IdIssuer select, .Mainview .MoneyOrder .InstrumentLog .IdType select {
    margin: 5px;
}

.Mainview .MoneyOrder .InstrumentLog .State select, .Mainview .MoneyOrder .InstrumentLog .IdIssuer select  {
    width:140px;
}

.Mainview .MoneyOrder .InstrumentLog .Zip input, .Mainview .MoneyOrder .InstrumentLog .IdType select {
    width: 260px;
}